<template>
  <div class="page">
    <div class="page-header">
      <el-button type="primary" @click="addItem" v-has="'add'">
        {{ $t('alarmStrategy.addStrategy') }}
      </el-button>
    </div>
    <div class="page-main">
      <el-form :inline="true" :model="tabelSearch">
        <div class="page-tools">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-plus iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <table-pagination
        memory="alarmStrategy"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip effect="dark" :content="$t('edit')" placement="top" :open-delay="1000">
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)">
              <i class="el-icon-edit iconfont icon-edit"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { strategySearch, strategyRemoveBatch } from '@/api/alarmStrategy'
import { TablePagination } from '@/components'

export default {
  name: 'AlarmList',
  components: {
    TablePagination,
  },
  data() {
    return {
      deviceId: this.$route.query.id,
      filter: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('alarmStrategy.strategyName'),
          prop: 'strategyName',
          minWidth: 140,
        },
        {
          label: this.$t('alarmList.alarmStrategy'),
          prop: 'strategyMode',
          minWidth: 160,
          filter: (val) => this.strategyMode[val],
        },
        {
          label: this.$t('alarmStrategy.notificationFrequency'),
          prop: 'frequency',
          minWidth: 210,
          filter: (val) => this.strategyFrequency[val],
        },
        {
          label: this.$t('alarmStrategy.status'),
          prop: 'status',
          minWidth: 100,
          filter: (val) => this.strategyStatus[val],
        },
        {
          label: this.$t('alarmStrategy.alarmReceiver'),
          prop: 'receiver',
          minWidth: 150,
        },
        {
          label: this.$t('alarmStrategy.alarmContent'),
          prop: 'content',
          minWidth: 140,
          filter: (val) => val.split(',').map(e => this.alarmType[+e]).join(this.language == 'en' ? ',' : '，'),
        },
        {
          label: this.$t('alarmStrategy.devices'),
          prop: 'deviceType',
          minWidth: 100,
          filter: (val) => this.selectDeviceType[val],
        },
        {
          label: this.$t('dateModified'),
          prop: 'updateTime',
          minWidth: 160,
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      tableSelection: [],
    }
  },
  computed: {
    ...mapState('memory', ['language']),
    ...mapGetters('dist', [
      'selectDeviceType',
      'strategyMode',
      'strategyFrequency',
      'strategyStatus',
      'alarmType'
    ]),
    ...mapGetters('session', ['pageTabelHeight']),
    datePicker: {
      get() {
        if (this.tabelSearch.beginTime && this.tabelSearch.endTime) {
          return [this.tabelSearch.beginTime, this.tabelSearch.endTime]
        } else {
          return ''
        }
      },
      set(val) {
        this.tabelSearch.beginTime = val ? val[0] : ''
        this.tabelSearch.endTime = val ? val[1] : ''
      },
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      strategySearch(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows // 表格数据
          this.tabelTotal = data.result.totalRows // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'updateTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('alarmStrategy.deleteTip'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id),
            args = this.tableSelection.map((e) => e.strategyName)
          strategyRemoveBatch({ ids, args })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 新增
    addItem() {
      this.$router.push('/alarmStrategy/addStrategy')
    },
    // 详情
    editItem(id) {
      this.$router.push({ path: '/alarmStrategy/editStrategy', query: { id } })
    },
  },
}
</script>
